<template>
  <div class="">
    <b-card
      class="border-0 p-2"
      body-class="hp-knowledge-basic-card text-center"
    >
      <b-row v-if="showInputFilter">
        <b-col :cols="filterInputCols" class="my-3">
          <b-form-group label-for="filter-input" class="mb-0">
            <b-input-group size="sm">
              <b-form-input
                id="filter-input"
                v-model="filter"
                style="height: 39.6px"
                type="search"
                :debounce="300"
                placeholder="Escribe para Inicia la Búsqueda"
              ></b-form-input>

              <b-input-group-append>
                <b-button
                  style="height: 39.6px"
                  variant="outline-info-3"
                  :disabled="!filter"
                  @click="filter = ''"
                  >Limpiar</b-button
                >
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <slot name="filters"></slot>
      </b-row>

      <!-- Main table element -->
      <b-table
        :items="items"
        :fields="fields"
        :current-page="currentPage"
        :per-page="perPage"
        :filter="filter"
        :filter-included-fields="filterOn"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        stacked="md"
        show-empty
        empty-filtered-text="Sin resultados en la búsqueda"
        empty-text="Sin rResultados para Mostrar"
        @filtered="onFiltered"
        class="mt-24"
      >
        <template #cell(questions)="{ item }">
          <slot name="questions" :item="item"></slot>
        </template>
        <template #cell(rejected)="{ item }">
          <slot name="rejected" :item="item"></slot>
        </template>
        <template #cell(pending_replacement)="{ item }">
          <slot name="pending_replacement" :item="item"></slot>
        </template>
        <template #cell(replaced)="{ item }">
          <slot name="replaced" :item="item"></slot>
        </template>
        <template #cell(status)="{ item }">
          <slot name="status" :item="item"></slot>
        </template>
        <template #cell(actions)="{ item }">
          <slot name="actions" :item="item"></slot>
        </template>
        <template #cell(name)="{ item }">
          <slot name="name" :item="item"></slot>
        </template>
        <template #cell(project_type_list)="{ item }">
          <slot name="project_type_list" :item="item"></slot>
        </template>

        <template #cell(checklist_type_show)="{ item }">
          <slot name="checklist_type_show" :item="item"></slot>
        </template>
      </b-table>

      <b-row
        class="mt-32 align-items-center justify-content-end"
        v-if="showFilter"
      >
        <b-col class="hp-flex-none w-auto">
          <b-form-group
            label-for="per-page-select"
            label-size="sm"
            class="mb-0"
          >
            <b-form-select
              id="per-page-select"
              v-model="perPage"
              :options="pageOptions"
            ></b-form-select>
          </b-form-group>
        </b-col>

        <b-col class="hp-flex-none w-auto">
          <b-pagination
            align="end"
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
          ></b-pagination>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    fields: {
      type: Array,
      default: () => [],
    },
    showFilter: {
      type: Boolean,
      default: true,
    },
    showInputFilter: {
      type: Boolean,
      default: true,
    },
    filterInputCols: {
      type: Number,
      default: 3,
    },
  },
  data() {
    return {
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 15, 50],
      sortBy: "",
      sortDesc: false,
      sortDirection: "desc",
      filter: null,
      filterOn: [],
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
  },
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
  watch: {
    items(newValue) {
      this.totalRows = this.items.length;
    },
  },
};
</script>
