<template>
  <div>
    <b-col
      cols="12"
      class="mb-24"
    >
      <apexchart
        v-if="!loading && series.length && series[0].data.length"
        height="350"
        type="line"
        :options="options"
        :series="series"
      />
      <div
        v-else
        class="text-center"
      >
        <h5 class="mb-0">
          <b-spinner class="align-middle mr-8" />
        </h5>
      </div>
    </b-col>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { BCol, BSpinner } from 'bootstrap-vue'

export default {
  components: {
    BCol,
    BSpinner,
  },
  props: {
    list: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  data() {
    return {
      loading: false,
      initSeries: [],
      initOptions: {
        chart: {
          height: 350,
          type: 'line',
          dropShadow: {
            enabled: true,
            color: '#000',
            top: 18,
            left: 7,
            blur: 10,
            opacity: 0.2,
          },
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
        },
        colors: ['#77B6EA', '#E91E63', '#9C27B0'],
        dataLabels: {
          enabled: true,
        },
        stroke: {
          curve: 'smooth',
        },
        grid: {
          borderColor: '#e7e7e7',
          row: {
            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
            opacity: 0.5,
          },
        },
        markers: {
          size: 1,
        },
        xaxis: {
          categories: [],
          labels: {
            show: true,
            style: {
              fontSize: '10px',
              fontWeight: 350,
            },
          },
        },
        yaxis: {
          min: 0,
        },
        legend: {
          position: 'top',
          horizontalAlign: 'right',
          floating: true,
          offsetY: -25,
          offsetX: -5,
        },
      },
    }
  },
  computed: {
    ...mapGetters({
      graphs: 'dashboard/graphs',
    }),
    options() {
      return { ...this.initOptions }
    },
    series() {
      return this.initSeries.map(series => ({ ...series }))
    },
  },
  watch: {
    graphs: {
      handler(newValue) {
        this.setDataGraphs(newValue)
      },
      deep: true,
    },
  },
  methods: {
    setDataGraphs(data) {
      this.loading = true
      this.initOptions.xaxis.categories = []
      this.initSeries = []

      Object.entries(data).forEach(([key, value], i) => {
        this.initOptions.xaxis.categories.push(key.split(' ')[0])

        value.secciones.forEach((seccion, j) => {
          const checklistCount = Object.entries(seccion.checklist_places).length

          if (i === 0) {
            this.initSeries.push({
              name: seccion.name,
              data: [checklistCount],
            })
          } else {
            this.initSeries[j].data.push(checklistCount)
          }
        })
      })

      setTimeout(() => {
        this.loading = false
      }, 300)
    },
  },
}
</script>
