<template>
  <div>
    <b-col
      cols="12"
      class="mb-24"
    >
      <datatable
        :items="filteredItems"
        :fields="fields"
      >
        <template #filters>
          <b-col
            cols="12"
            md="3"
            sm="12"
          >
            <b-form-select
              id="sectionType"
              v-model="selectedSection"
              :options="wrappedSections"
              value-field="id"
              text-field="name"
              class="my-3"
              placeholder="Selecciona datos una Sección"
              @change="filterBySection"
            />
          </b-col>
        </template>
        <template #date="{ item }">
          <span>
            {{ item.date.substring(0, 10) }}
          </span>
        </template>
      </datatable>
      <!-- <div
        v-else
        class="text-center"
      >
        <h5 class="mb-0">
          <b-spinner class="align-middle mr-8" />
        </h5>
      </div> -->
    </b-col>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { BCol } from 'bootstrap-vue'
import Datatable from '@/view/components/common/datatable/Datatable.vue'
import moment from 'moment'

export default {
  components: {
    // BSpinner,
    BCol,
    Datatable,
  },
  props: {
    list: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  data() {
    return {
      loading: false,
      selectedSection: null,
      textFilterSection: 'Filtrar datos por Sección',
      filteredItems: [],
      items: [],
      fields: [
        {
          key: 'date',
          label: 'Fecha',
          sortable: false,
          class: 'text-center',
        },
        {
          key: 'template_name',
          label: 'Plantilla',
          sortable: false,
          class: 'text-center',
        },
        {
          key: 'place',
          label: 'Punto Instalación',
          sortable: false,
          class: 'text-center',
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      graphs: 'dashboard/graphs',
      sections: 'project/sections',
    }),
    wrappedSections() {
      return [
        { id: null, name: this.textFilterSection, disabled: true },
        ...this.sections,
      ]
    },
  },
  watch: {
    sections: {
      handler(newValue) {
        this.selectedSection = newValue[0].id
        this.filterBySection(this.selectedSection)
      },
      deep: true,
    },
    graphs: {
      handler(newValue) {
        this.setDataGraphs(newValue)
      },
      deep: true,
    },
  },
  methods: {
    resolveSectionName(id) {
      const { name } = this.sections.find(e => e.id === id)
      return name
    },
    setDataGraphs(data) {
      this.loading = true
      this.items = []
      Object.entries(data).forEach(([key, value]) => {
        value.secciones.forEach(seccion => {
          Object.entries(seccion.checklist_places).forEach(checklistPlace => {
            this.items.push({
              date: `${moment(checklistPlace[1].answer[0].created_at).format('DD/MM/YYYY HH:mm')} hrs`,
              template_name: checklistPlace[1].name,
              place: checklistPlace[1].answer[0].place.name || '',
              section: checklistPlace[1].section,
              section_name: this.resolveSectionName(checklistPlace[1].section),
            })
          })
        })
      })
      this.filterBySection(this.selectedSection)
      setTimeout(() => {
        this.loading = false
      }, 300)
    },
    filterBySection(id) {
      if (id !== null) {
        this.filteredItems = this.items.filter(e => e.section === id)
      } else {
        this.filteredItems = this.items
      }
    },
  },
}
</script>
