<template>
  <div>
    <b-col
      cols="12"
      class="mb-24"
    >
      <apexchart
        v-if="!loading && initSeries[0].data.length"
        height="350"
        type="bar"
        :options="options"
        :series="series"
      />
      <div
        v-else
        class="text-center"
      >
        <h5 class="mb-0">
          <b-spinner class="align-middle mr-8" />
        </h5>
      </div>
    </b-col>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { BCol, BSpinner } from 'bootstrap-vue'

export default {
  components: {
    BCol,
    BSpinner,
  },
  props: {
    list: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  data() {
    return {
      loading: false,
      initOptions: {
        chart: {
          id: 'vuechart-chart-bar-total',
          toolbar: {
            show: true,
          },
        },
        xaxis: {
          categories: [],
          convertedCatToNumeric: false,
          labels: {
            show: true,
          },
        },
      },
      initSeries: [
        {
          name: 'Cantidad',
          data: [],
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      graphs: 'dashboard/graphs',
    }),
    options() {
      return { ...this.initOptions }
    },
    series() {
      return this.initSeries.map(series => ({ ...series }))
    },
  },
  watch: {
    graphs: {
      handler(newValue) {
        this.setDataGraphs(newValue)
      },
      deep: true,
    },
  },
  methods: {
    setDataGraphs(data) {
      this.loading = true
      this.initOptions.xaxis.categories = []
      this.initSeries[0].data = []
      Object.entries(data).forEach(([key, value]) => {
        this.initOptions.xaxis.categories.push(key.split(' ')[0])
        this.initSeries[0].data.push(value.total)
      })

      setTimeout(() => {
        this.loading = false
      }, 300)
    },
  },
}
</script>
